<template>
  <Profile :profile="record" :viewer="user.id" />
</template>

<script>
import http from "../../../../common/services/http";
import store from "../../../../common/services/store";
import router from "../router";
import { defineComponent, reactive, ref, toRefs, onMounted } from "vue";
import Profile from "../../../../common/components/Profile.vue";

export default defineComponent({
  components: {
    Profile
  },
  setup() {
    const record = ref({});

    const { user } = toRefs(store.state);

    onMounted(() => {
      http
        .get(`/api/users/${router.currentRoute.value.params.id}`)
        .then(response => {
          record.value = response.data;
        });
    });

    return {
      user,
      record
    };
  }
});
</script>
